import {BasicObject} from '@/types';
import {http, quickHttp} from '@/utils';
import dayjs from 'dayjs';

export interface DigitCodeItem {
  codeWinAmount: number;
  /** 购买号码坐标（直接split('')） */
  indexCode: string;
  isWin: number;
  pickCount: number;
  /** 购买的号码（直接split('')） */
  wonCode: string;
  /** 彩票金额 */
  pickAmount: string;
}

export interface DigitOrderData {
  codeLists: DigitCodeItem[];
  isWin: number;
  openTime: number;
  orderTime: number;
  /** 彩票名称 */
  pickName: string;
  /** 中奖金额 */
  wonTotalAmount: number;
  /** 当前中奖结果 */
  drawResult: string;
  orderGroup: string;
}

export interface PageInfo {
  pageNo: number;
  pageSize?: number;
}

export interface DigitParams extends PageInfo {
  pickThreeId: number;
}

export interface DigitDrawResultItem {
  drawResult: string;
  drawTime: string;
  pickName?: string;
  pickNo?: string;
}

export interface DigitResultItem {
  drawDate: number;
  drawResultList: DigitDrawResultItem[];
  data: DigitDrawResultItem[];
}

export interface DigitTypeItem {
  pickName: string;
  id: number;
}

export interface PickTimesContent {
  drawTime: string;
  isOverdue: number;
  timeId: number;
  timeName: string;
  drawTimeLong: number;
}

export interface PickInfosContent {
  pickAmount: number;
  pickInfoId: number;
  pickLevel: number;
  pickTitle: string;
  pickWinAmount: number;
  drawTimeLong: number;
}

export interface DigitOrderCodeItem {
  /** 号码盈利金额 */
  codeWinAmount: number;
  /** 用户购买的彩票 */
  indexCode: string;
  isWin: 1 | 0;
  /** 购买数量 */
  pickCount: number;
  pickAmount: number;
  /** 开奖号码 */
  wonCode: string;
  winAmount: number;
}

export interface DigitOrderItem {
  createTime: string;
  drawTime: string;
  gameDrawTime: number;
  /** 订单号 */
  orderGroup: string;
  /** 彩票名 */
  timeName: string;
  totalAmount: number;
  winAmount: number;
  wonCode: string;
  shareAward: number;
  gameIconUrl?: string;
  gameIcon: string;
  codeLists: DigitOrderCodeItem[];
}

export interface betsListItem {
  level: number;
  type: string;
  value: string;
  count: number;
  basePrice: number;
  pickInfoId: number;
}

export interface DigitOrderResponse {
  content: DigitOrderItem[];
  totalPages: number;
  totalSize: number;
}

export interface QuickDigitOrderResponse {
  records: DigitOrderItem[];
  totalCount: number;
}

export function getDigitResultList(pageNo: number, searchId?: number) {
  return http.post<DigitParams, DigitResultItem[]>('app/pick/result/list/web', {
    pickThreeId: searchId || 0,
    pageNo,
  });
}

export function getDigitLastOrder() {
  return http.post<null, DigitOrderData>('app/pick/result/last/order');
}

export const getDigitOrderList = (
  pageNo: number = 1,
  pageSize = 10,
  orderStatus = '3',
) => {
  const startTime = dayjs().format('YYYY-MM') + '-01 00:00:00';
  const endTime = dayjs().format('YYYY-MM-DD') + ' 23:59:59';
  const yearMonth = dayjs().format('YYYYMM');
  return http.post<
    {
      pageNo: number;
      pageSize: number; // 这个参数没有用到,后台固定返回十条
    },
    DigitOrderResponse
  >('app/pick/order/listV2', {
    pageNo,
    orderStatus,
    pageSize,
    startTime,
    endTime,
    yearMonth,
    gameType: 2,
  });
};

export interface DigitPickInfoQueryResponse {
  pickTimes: PickTimesContent[];
  pickInfos: PickInfosContent[];
}

export interface PickGamesContent {
  currentPickNo: string;
  drawTimeLong: number;
  cycle: number;
  stopBetSec: number;
  lastResult?: {
    drawResult: string;
    pickNo: string;
  };
  pickThreeID: number;
  timeName: string;
}

export interface DigitPickInfoQuickDataResponse {
  pickInfos: PickInfosContent[];
  pickGames: PickGamesContent[];
}

export interface DigitLastDrawResultResponse {
  code: number;
  data: DigitLastDrawResultData;
  msg: string;
  success: boolean;
}

export interface DigitQuickResultResponse {
  totalCount: number;
  drawResultList: DigitDrawResultItem[];
}

export interface DigitLastDrawResultData {
  autoDrawTimestamp: number;
  createTime: number;
  drawDate: number;
  drawResult: string;
  drawTime: string;
  drawTimestamp: number;
  id: number;
  packageId: number;
  pickName: string;
  pickNo: string;
  pickThreeId: number;
  pickType: string;
  resultStatus: number;
  timeId: number;
}

export interface DigitOrderCreateParams {
  pickThreeId: number;
  timeIds: number[]; // timeIds 即多个时间段
  totalAmount: number; // 总金额
  levelOne: LevelOne[]; // 彩票等级 1 2 3
  levelTwo: LevelOne[];
  levelThr: LevelOne[];
}

export interface LevelOne {
  pickInfoId: number;
  pickIndex: string;
  pickNumber: string;
  pickCount: number;
}

export interface DigitCarteResultResponse {
  code: number;
  data: string;
  msg: string;
  success: boolean;
}

export type DigitType = 'A' | 'B' | 'C' | 'AB' | 'BC' | 'AC' | 'ABC';

/**
 * 获取彩票信息
 * @param pickThreeId
 * @returns
 */
export const digitPickInfoQuery = (pickThreeId: string) =>
  http.post<null, DigitPickInfoQueryResponse>('app/pick/info/query', {
    pickThreeId,
  });

/**
 * pick获取彩票开奖最后一期
 * POST /pick/result/lastDrawResult
 * @param pickType 游戏类型
 * @returns
 */
export const digitLastDrawResult = (pickType = 'KuberLottery') =>
  http.post<null, DigitLastDrawResultResponse>(
    'app/pick/result/lastDrawResult',
    {
      pickType,
    },
  );

/**
 * 购买pick3彩票
 * POST app/pick/order/create
 * 接口ID：47248861
 * 接口地址：https://app.apifox.com/link/project/1203767/apis/api-47248861
 * @returns
 */
export const digitOrderCreate = (data: DigitOrderCreateParams) =>
  http.post<null, string>('app/pick/order/create', data);

// quick 部分
// const tagMap: Record<number, string> = {
//   1: 'an',
//   3: 'sn',
//   5: 'bh',
// };
export const getQuickPickData = () =>
  quickHttp.get<null, DigitPickInfoQuickDataResponse>(
    'games/quick3/info/query',
  );

export const getQuickPickResult = (data: {
  pickThreeId: number;
  pageNo: number;
  pageSize: number;
}) =>
  quickHttp.post<null, DigitQuickResultResponse>(
    'games/quick3/result/history',
    data,
  );

export interface quickCreateParams {
  pickThreeId: number;
  pickNo: string;
  totalAmount: number;
  tickets: LevelOne[];
}

export const crateQuickGame = (data: quickCreateParams) =>
  quickHttp.post<quickCreateParams, string>('games/quick3/order/create', data);

export const quickDrawByIssueNum = (pickThreeId: number, pickNo: string) =>
  quickHttp.post<null, number>('games/quick3/order/prize', {
    pickThreeId,
    pickNo,
  });

export const quickGameOrder = (data: {
  pickThreeId: number;
  pageNo: number;
  pageSize: number;
  orderStatus: number;
}) =>
  quickHttp.post<null, QuickDigitOrderResponse>(
    'games/quick3/order/list',
    data,
  );

const KEYMAP = {
  BhutanJackpot: 'PICK_BHUTAN',
  'Dear Lottery': 'PICK_DEAR_LOTTERY',
  'Kerala Lottery': 'PICK_KERALA_LOTTERY',
  Lucwin: 'PICK_LUCWIN',
  KuberLottery: 'PICK_KUBER',
  Chennailotto: 'PICK_CHENN',
  Skywin: 'PICK_SKYWIN',
  GoldenJackpot: 'PICK_GOLDENJACKPOT',
  'Quick3D 3min': 'QUICK3D_3MIN',
  'Quick3D 5min': 'QUICK3D_5MIN',
} as BasicObject;

export const getRule = (pickName: string) =>
  http.post<null, {content: string}>('app/protocol/find', {
    // uniqueKey: KEYMAP[pickName] || 'PICK_GOIDEN',
    uniqueKey: KEYMAP[pickName] || 'PICK_GOLDENJACKPOT',
    /** TODO */
    lang: 'English',
  });
